<template>
  <div
    class="person"
    :data-chat="dataChat"
  >
    <div class="user-info">
      <div class="user-head mr-2">
        <img
          src="assets/img/profile-22.jpg"
          alt="avatar"
          v-if="hasPhoto"
        >
        <i
          class="las la-user la-3x"
          v-else
        />
      </div>
      <div class="user-body">
        <h5
          class="text-truncate strong mb-0 mt-1 chat-user-name"
          data-name="Amanda Winston"
        >
          {{ fullname }}
        </h5>
        <p class="text-muted font-11 text-truncate mb-0">
          Askla sasasajs asja saisa sasa sah
        </p>
      </div>
      <div class="user-footer ml-2 text-right">
        <span class="chat-time font-10 text-success-teal">12:25</span>
        <div>
          <span class="pinned"><i class="las la-thumbtack" /></span>
          <span class="chat-count">99</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        user: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    computed: {
        hasUser(){
            return this.user !== null && this.user !== undefined
        },
        dataChat(){
            if(this.hasUser) return 'person'+this.user.uid
            return 'person0'
        },
        fullname(){
            if(this.hasUser) return this.user.fullname
            return '-'
        },
        hasPhoto(){
            if(this.hasUser) return this.user.photo !== null
            return false
        }
    }
}
</script>

<style>

</style>